.mini-details-vehicle-card-main-container {
  width: 390px;
  height: 90vh;
  margin: 10% auto;
  background: #ffffff;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}
.mini-details-vehicle-tab-container-new {
  overflow: scroll;
  position: relative;
  height: 50vh;
}
.mini-details-vehicle-img {
  width: 100%;
  /* background-color: #c7c7c7; */
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mini-details-vehicle-img img {
  width:200px;
  height:150px;
}

.MuiTabs-indicator {
  background-color: #016938 !important;
}
.mini-details-vehicle-single-list {
  margin-bottom: 1.2rem;
  display: flex;
  align-items: center;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-size: 12px !important;
  font-weight: 600 !important;
}
.mini-details-vehicle-single-list-left {
  font-size: 14px;
  margin: 0px 5px;
  position: relative;
}
.mini-details-vehicle-single-list-right {
  font-size: 14px;
  margin: 0px 5px;
  width: 100%;
  position: absolute;
  color: rgb(65, 64, 64);
  left: 56%;
}
.btn_card_vehicle {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3rem;
  border: 2px solid black;
  /*background-color: #9e194a;
  s*/
  color: #016938;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;
  transition: 0.4s ease all;
  -webkit-transition: 0.4s ease all;
  -moz-transition: 0.4s ease all;
  -ms-transition: 0.4s ease all;
  -o-transition: 0.4s ease all;
}
.btn_card_vehicle:hover {
  background-color: #016938;
  color: white;
  outline: none;
  border: none;
  transition: 0.8s ease all;
  -webkit-transition: 0.8s ease all;
  -moz-transition: 0.8s ease all;
  -ms-transition: 0.8s ease all;
  -o-transition: 0.8s ease all;
  transform: translate(1.2);
  -webkit-transform: translate(1.2);
  -moz-transform: translate(1.2);
  -ms-transform: translate(1.2);
  -o-transform: translate(1.2);
}

.mini-vehicles-attachment-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: scroll;
  height: 70vh;
  width: 100%;
}
@media only screen and (max-width: 499px) {
  .mini-vehicles-attachment-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow: scroll;
    height: 55vh;
    width: 100%;
  }
  .mini-details-vehicle-body {
    width: 100%;
    /* height: 80%; */
    overflow: hidden;
    margin: 0 auto;
    /* background-color: red;s */
  }
  .mini-details-vehicle-tab-container-new {
    width: 100%;
    height: 70vh;
    /* overflow: scroll; */
    /* background-color: red; */
    /* height: 100vh; */
  }
  .mini-details-vehicle-card-main-container {
    width: 96%;
    /* margin: 0 auto; */

    /* height: 100%; */
    /* margin: 10% auto; */
    background: #ffffff;
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    margin-top: 1rem;
    margin-left: 1.2rem;
    /* background-color: red; */
    /* margin-top: 0px; */
    /* background: blue; */
  }

  .mini-details-vehicle-img {
    width: 100%;
    /* background-color: #c7c7c7; */
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MuiTabs-indicator {
    background-color: #016938 !important;
  }
  .mini-details-vehicle-single-list {
    margin-bottom: 1.2rem;
    display: flex;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 10px !important;
    font-weight: 600 !important;
  }
  .mini-details-vehicle-single-list-left {
    font-size: 14px;
    margin: 0px 5px;
    position: relative;
  }
  .mini-details-vehicle-single-list-right {
    font-size: 14px;
    margin: 0px 5px;
    position: absolute;
    color: rgb(65, 64, 64);
    left: 55%;
  }
  .btn_card_vehicle {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3rem;
    border: 2px solid black;
    /*background-color: #9e194a;
    s*/
    color: #016938;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
    transition: 0.4s ease all;
    -webkit-transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -ms-transition: 0.4s ease all;
    -o-transition: 0.4s ease all;
  }
  .btn_card_vehicle:hover {
    background-color: #016938;
    color: white;
    outline: none;
    border: none;
    transition: 0.8s ease all;
    -webkit-transition: 0.8s ease all;
    -moz-transition: 0.8s ease all;
    -ms-transition: 0.8s ease all;
    -o-transition: 0.8s ease all;
    transform: translate(1.2);
    -webkit-transform: translate(1.2);
    -moz-transform: translate(1.2);
    -ms-transform: translate(1.2);
    -o-transform: translate(1.2);
  }
  #transporter_type {
    font-size: 0.7rem;
  }
}
