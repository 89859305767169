.customer-sidebar-menutext {
  color: #b6b6bb;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 10px;
}
.customer-sidebar-menuContain {
  display: flex;
  /* justify-content: center; */
  /* padding: 10px; */
  align-items: center;
  width: 90%;
}
.customerSidebar-active {
  background: #f4ffee;
  color: #076b38;
  border-radius: 0px 40px 40px 0px;
}
.customer-sidebar-menuLogo {
  margin-left: 6px;
}
.customer-sidebar-menuborder {
  background: #076b38;
  border-radius: 0px 4px 4px 0px;
  width: 6px;
  height: 50px;
}
.customer-sidebar-menuborder-white {
  background: #ffffff;
  border-radius: 0px 4px 4px 0px;
  width: 6px;
  height: 50px;
}
.customer-sidebar-main {
  height: 100vh;
  position: relative;
}

.logout {
  position: absolute;
  bottom: 20px;
  left: 40px;

}
.logout-button{
  padding: 10px 20px;
  background-color: #076b38;
  color: white;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.customer-details {
margin-bottom: 10px;
display: flex;
align-items: center;
justify-content: flex-start;
width: 100%;
color: green;
}
.logo-customer{
  width: 100%;
  
}
