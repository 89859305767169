.receivable-details-sidebar {
  flex: 1.7;
  width: 100%;
  height: 100vh;
}
.tds_error{
  color: rgb(247, 8, 8);
  padding: .2rem 0rem;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgb(252, 220, 220);
  font-family: "poppins";
}
.receivable-approve{
  padding: .1rem .1rem;
  outline: none;
  display: flex;
  width: 60px;
  height: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-right: 5px;
  border: 1px solid grey;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  font-size: 10px;
  font-family: "poppins";
}

.add_tds_button{
  padding: .3rem .4rem;
  outline: none;
  display: flex;
  font-weight: bold;
  color: #016938;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-right: 5px;
  border: 1px solid grey;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  font-size: 10px;
  font-family: "poppins";
}
.button_tds_accept_reject{
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.tds_form{
  padding: 5px ;
  background-color: #e8f9e0;
  margin-top: 5px;
}
.tds_close_button{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.add_tds_button_submit{
  padding: .2rem 0.4rem;
  background-color: #076b38;
  color:white;
  border-radius: 5px;
  font-weight: normal;
  outline: none;
  width: 120px;
  cursor: pointer;
  border: 1px solid grey;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  /* width: 90px; */
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
}
.tds_fields_container{
  width: 100%;
  padding: 5px 0px;
  /* gap: 5px; */
}
.tds_button_container{
  width: 100%;
  margin-top: .5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.receivable-details-body {
  flex: 9.5;
  width: 80%;
  height: 100vh;
  background-color: #f9f9f9;
  /* padding: 20px 0px; */
  /* position: sticky; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.receivable-sidebar {
  flex: 1.7;
  width: 100%;
  height: 100vh;
}
.receivable-right-container {
  flex: 9.5;
  width: 80%;
  height: 100vh;
  background-color: #f9f9f9;
  /* padding: 20px 0px; */
  /* position: sticky; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
}
.receivable-details-btn {
  color: #076b38;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px 16px;
  background: white;
  border: 1px solid #076b38;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.receivable-top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.receivable-status-txt {
  color: #016938;
  font-weight: 500;
  font-size: 14px;
}
.receivable-amount-big-txt {
  color: #454545;
  font-weight: 800;
  font-size: 18px;
}
.receivable-amount-txt {
  color: #454545;
  font-weight: 600;
  font-size: 12px;
  margin: 3px 0px;
}
.receivable-vehicle-txt {
  color: #454545;
  font-weight: 600;
  font-size: 12px;
}
.receivable-pay-utr-txt {
  color: #2c2c2c;
  font-family: "poppins";
  font-weight: 500;
  font-size: 12px;
}
.receivable-pay-remarks-text {
  font-weight: 400;
  font-size: 12px;
  width: 100%;
  word-wrap: break-word;
  color: #575757;
}
.receivable-pay-single {
  border-radius: 6px;
  background: rgba(76, 217, 100, 0.08);
  padding: 10px;
  margin: 10px 0px;
}
.receivable-payDetails-container {
  margin: 10px 0px;
  height: 300PX;
  overflow-y: scroll;
  /* background-color: #076b38; */
}
.receivable-line-amount {
  display: flex;
  justify-content: space-between;
  margin: 4px 0px;
  width: 100%;

  /* background-color: #016938; */
}
.receivable-pay-amount-big-txt {
  font-size: 14px;
  color: #454545;
  font-weight: 600;
}
.receivable-pay-amount-txt {
  font-size: 12px;
  font-weight: 500;
  font-family: "poppins";
  color: #454545;
  /* font-weight: 600; */
}
.receivable-pay-top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 90%; */
}
.receivable-pay-remarks-text {
  margin-top: 8px;
}
.receivable-edit-btn {
  color: #076b38;
  font-size: 10px;
  font-weight: 600;
  border-radius: 50px;
  border: 1px solid #076b38;
  background-color: transparent;
  padding: 6px 10px;
  width: 66px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.receivable-record-btn {
  color: #076b38;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid #076b38;
  background-color: transparent;
  padding: 5px 15px;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.receivable-record-head {
  color: #000;
  font-weight: 700;
  font-size: 16px;
}
.receivable-utr-txt {
  color: #454545;
  font-weight: 600;
  font-size: 14px;
}
.receivable-utr-txt_tds {
  color: #454545;
  font-weight: 500;
  font-size: 10px;
}
.receivable-details-btn {
  background-color: #076b38;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  /*border: 1px solid #076b38;
  */color: white;
  padding: 5px 15px;
  cursor: pointer;
  margin-left: 13px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.receivable-bold-txt {
  color: #454545;
  font-weight: 700;
  font-size: 14px;
}
.receivable-bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.receivable-head-text {
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
  color: #016938;
}
.receivable-head-container {
  background: #ffffff;
}
.receivable-head-row {
  background: #ffffff;
  display: flex;
  justify-content: space-between;

  padding: 1% 3%;
}
.receivable-search-row {
  padding: 10px;
}
.receivable-single-item-container {
  background: #fff;
  padding: 15px 13px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}
.receivable-drawer-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.receivable-single-top-right {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.receivable-customer-namee {
  /* margin-left: 6px; */
  max-width: 140px;
  /* width: ; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.receivable-number-text {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}
.receivable-middle-row {
  margin: 5px 0px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.receivable-drawer-container {
  padding: 20px;
}
.receivable-input-text {
  font-size: 15px;
  font-weight: 500;
}
.receivable-remarks-text {
  font-size: 18px;
  font-weight: 600;
}
.receivable-remarks-inputfield-conatiner {
  width: 100%;
  height: 200px;
  margin-top: 4px;
}
.receivable-input-container {
  margin: 10px 0px;
}
.receivable-inputfield {
  width: 93%;
}
.receivable-inputfield_tdsField {
  width: 100%;
  background-color: white;
  outline: none;

}
.receivable-drawer-head-text {
  font-size: 22px;
  font-weight: 600;
  color: #016938;
  margin-bottom: 10%;
}

.receivable-remarks-textarea {
  width: 93%;
  height: 100%;
  margin-top: 0px;
  /* resize: none; */
  padding: 10px;
  border: 1px solid #bab9b9;
}
.receivable-remarks-textarea_tds {
  width: 100%;
  height: 100%;
  /* resize: none; */
  padding: 5px;
  border: 1px solid #e5e5e5;
}
.receivable-remarks-textarea:focus {
  border-color: #999;
}
.error {
  font-size: 12px;
  color: red;
}
.receivable-remarks-submit-btn-cover {
  width: 95%;
  padding: 18px 0px;
  display: flex;
  justify-content: flex-end;
}
.receivable-remarks-submit-btn {
  /* width: 20%; */
  background: #ffffff;
  border: 1px solid #a10a0a;
  border-radius: 50px;
  color: #a10a0a;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  padding: 8px 23px;
  cursor: pointer;
}

.receivable-customer-text {
  color: #575757;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 3px 0px;
}
.receivable-grid-main-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: min-content;
  gap: 20px 20px;
  /* width: 90%; */
  /* justify-content: center; */
}
.receivable-list-container {
  padding: 25px;
}
.receivable-light-text {
  font-weight: 500;
}

.receivable-date-text {
  color: #575757;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  margin-left: 30px;
}
.receivable-slim-text {
  color: #575757;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
}
.receivable-bold-text {
  color: #454545;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}
.receivable-bold-text-large {
  color: #454545;
  font-weight: 700;
  font-size: 18px;
  /* line-height: 15px; */
}
.receivable-single-top-row {
  display: flex;
  justify-content: space-between;
  margin: 3px 0px;
}
.receivable-add-quote-btn {
  padding: 8px 16px;
  border: none;
  background: #076b38;
  margin-left: 10px;
  border-radius: 50px;
  color: #fff;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  cursor: pointer;
  width: 110px;
  /* height: 30px; */
}
.receivable-save-quote-btn {
  padding: 8px 16px;
  border: none;
  background: #076b38;
  margin-left: 10px;
  border-radius: 50px;
  color: #fff;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  cursor: pointer;
  width: 110px;
  height: 40px;
}
.receivable-tab-menuOption-contain {
  display: flex;
  margin: 10px 15px;
}
.receivable-search-input {
  border: none;
  width: 94%;
  outline: none;
}
.receivable-search-container {
  background: #fff;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  width: 70%;
}
.receivable-tab-menu-option {
  margin: 0px 2%;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #b6b6bb;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  cursor: pointer;
}
.receivable-tab-bottom-rectangle {
  background: #076b38;
  border-radius: 5px 5px 0px 0px;
  height: 4px;
  width: 100%;
}
.receivable-active-tab {
  color: #076b38;
  font-weight: 600;
  border: 2px solid #076b38;
  border-radius: 5px 5px 0px 0px;
}
.receivable-single-middle-row {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  align-items: center;
}
.receivable-single-bottom-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.receivable-reject-btn {
  border: 1px solid #cf1004;
  background: #ffffff;
  border-radius: 50px;
  color: #cf1004;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  padding: 8px 16px;
  cursor: pointer;
}
.single-stop {
  color: #076b38;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}
.receivable-single-stop-text {
  color: #076b38;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.loading_more_data {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: white;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #016938;
  width: 100%;
  text-align: center;
}
.tds_deduction_add_route_msg{
  font-size: 10px;
  text-decoration: underline;
  color: rgb(70, 70, 240);
  cursor: pointer;
}
.tds_deduction_add_route_msg_click{
  font-size: 10px;
}
@media only screen and (max-width: 480px) {
  .receivable-pay-bottom{
    width: 100%;
  }
  .receivable-line-amount {
    display: flex;
    justify-content: space-between;
    margin: 4px 0px;
    width: 100%;
    /* background-color: #016938; */
  }
  .receivable-payDetails-container {
    margin: 10px 0px;
    height: 300PX;
    overflow-y: scroll;
    width: 100%;
    /* background-color: #076b38; */
  }
  .receivable-sidebar {
    flex: 1.7;
    width: 100%;
    height: 100vh;
  }
  .input-div {
    width: 100%;
    /* background-color: #016938; */
  }
  .receivable-right-container {
    flex: 9.5;
    width: 100vw;
    height: 100vh;
    background-color: #f9f9f9;
    /* padding: 20px 0px; */
    /* position: sticky; */
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }
  .receivable-head-text {
    font-weight: 700;
    font-size: 18px;
    margin-top: 4px;
    line-height: 20px;
    color: #016938;
  }
  .receivable-head-container {
    background: #ffffff;
  }
  .receivable-head-row {
    background: #ffffff;
    display: flex;
    justify-content: space-between;

    padding: 0% 0%;
  }
  .receivable-search-row {
    padding: 10px;
  }
  .receivable-single-item-container {
    background: #fff;
    padding: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .receivable-customer-namee {
    margin-left: 6px;
    max-width: 140px;
    /* width: ; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .receivable-bold-txt {
    color: #454545;
    font-weight: 700;
    font-size: 12px;
  }
  .receivable-vehicle-txt {
    font-size: 11px;
  }
  .receivable-number-text {
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
  }
  .receivable-drawer-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 0px 20px;
    /* background-color: #016938; */
    /* padding: 20px; */
    width: 100%;
  }
  .receivable-input-text {
    font-size: 15px;
    font-weight: 500;
    /* background-color: #016938
      ; */
  }
  .receivable-remarks-text {
    font-size: 18px;
    font-weight: 600;
    padding: 10px 20px;
  }
  .receivable-remarks-inputfield-conatiner {
    width: 100%;
    height: 200px;
    margin-top: 20px;
  }
  .receivable-input-container {
    margin: 10px 0px;
    width: 100%;
  }
  .receivable-inputfield {
    width: 100%;
  }
  .receivable-drawer-head-text {
    font-size: 22px;
    font-weight: 600;
    color: #016938;
    width: 100%;
    /* background-color: #016938; */
    padding: 10px 0px;
    margin-bottom: 10%;
  }

  .receivable-remarks-textarea {
    width: 93%;
    height: 100%;
    /* resize: none; */
    padding: 20px;
    border: 1px solid #e5e5e5;
  }
  .receivable-remarks-textarea_tds {
    width: 100%;
    height: 100%;
    /* resize: none; */
    padding: 20px;
    border: 1px solid #e5e5e5;
  }
  .receivable-remarks-textarea:focus {
    border-color: #999;
  }
  .receivable-remarks-submit-btn-cover {
    width: 100%;
    padding: 18px 0px;
    display: flex;
    justify-content: flex-end;
  }
  .receivable-remarks-submit-btn {
    /* width: 20%; */
    background: #ffffff;
    border: 1px solid #a10a0a;
    border-radius: 50px;
    color: #a10a0a;
    font-weight: 700;
    margin-right: 20px;
    font-size: 12px;
    line-height: 18px;
    padding: 8px 23px;
    cursor: pointer;
  }

  .receivable-customer-text {
    color: #575757;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 3px 0px;
  }
  .receivable-grid-main-container {
    display: grid;
    grid-template-columns: auto;
    gap: 30px 30px;
    /* height: 100vh; */
    /* width: 90%; */
    /* justify-content: center; */
  }
  .receivable-list-container {
    padding: 25px;
  }
  .receivable-date-text {
    color: #575757;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
  }
  .receivable-slim-text {
    color: #575757;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
  }
  .receivable-bold-text {
    color: #454545;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }
  .receivable-single-top-row {
    display: flex;
    justify-content: space-between;
    margin: 3px 0px;
  }
  .receivable-add-quote-btn {
    padding: 8px 16px;
    border: none;
    background: #076b38;
    margin-left: 10px;
    border-radius: 50px;
    color: #fff;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    cursor: pointer;
    width: 110px;
    /* height: 30px; */
  }
  .receivable-save-quote-btn {
    padding: 8px 16px;
    border: none;
    background: #076b38;
    margin-left: 10px;
    border-radius: 50px;
    color: #fff;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    cursor: pointer;
    width: 110px;
    height: 40px;
  }
  .receivable-tab-menuOption-contain {
    display: flex;
    margin: 10px 15px;
  }
  .receivable-search-input {
    border: none;
    width: 94%;
    outline: none;
  }
  .receivable-search-container {
    background: #fff;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    width: 70%;
  }
  .receivable-tab-menu-option {
    margin: 0px 2%;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #b6b6bb;
    font-weight: 500;
    font-size: 14px;
    /* line-height: 27px; */
    cursor: pointer;
  }
  .receivable-tab-bottom-rectangle {
    background: #076b38;
    border-radius: 5px 5px 0px 0px;
    height: 4px;
    width: 100%;
  }
  .receivable-active-tab {
    color: #076b38;
    font-weight: 600;
    border: 2px solid #076b38;
    border-radius: 5px 5px 0px 0px;
  }
  .receivable-single-middle-row {
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
  }
  .receivable-single-bottom-row {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .receivable-reject-btn {
    border: 1px solid #cf1004;
    background: #ffffff;
    border-radius: 50px;
    color: #cf1004;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    padding: 8px 16px;
    cursor: pointer;
  }
  .single-stop {
    color: #076b38;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  }
  .receivable-single-stop-text {
    color: #076b38;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  }

  .loading_more_data {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: white;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #016938;
    width: 100%;
    text-align: center;
  }
  .receivable-button-contain {
    display: flex;
    /* flex-direction: column; */
  }
  .receivable-bottom-row {
    flex-direction: column;
  }
  .receivable-amount-contain {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
    margin-bottom: 10px;
  }
}
