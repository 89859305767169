.topbar-container{
    width: 100%;
    /* height: 6rem; */
    display: flex;
    align-items: center;
    background-color:#076b38;
    justify-content: flex-end;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    padding:.3rem 1rem;
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
}
.user_name_conatainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-name-text{
    color: white; font-Family: "poppins";font-Weight: 500;font-size: 12px;margin-right: 5px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.logout_button_container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* font-size: 10px; */
}
