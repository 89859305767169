.dialogBox{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.subDialogBox{
    display: flex;
    flex-direction: row;
    gap: 15%;
    align-items: center;
    font-size: small;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 5px;
}
.modalCrossDiv{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: -111;
}
.subDialogBox2{
    display: flex;
    flex-direction: row;
    gap: 50px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 5xpx;
}

.image_button_container{
    display: flex;
    flex-direction: row;
}
.subDialogBoxChild{
    display: flex;
    flex-direction: column;
}
.transporter_drawer_headings{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.attatchmentsLoadingDiv{
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* .attatchmentsLoadingDiv>h3{
 border: 1px solid red;
} */
.greyText{
    color: grey
}
.sideSlideHeading{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -10px;
}
.sideSlideHeading>span{
    font-size: medium;
    color: black;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2px;
}