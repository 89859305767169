.mainDiv{
    display: flex;
    flex-direction: column;
    height: 55vh;
    gap: 5%;
}
.fromToAndGo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 5px;
}
.advanceOptionDiv{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.datePicker{
    display: flex;
    flex-direction: column;
}
.cascadingInput{
    height: 35px;
    border-radius: 5px;
}
::placeholder{
    padding: 5px;
}
.cascadingInput{
    padding: 0 5px;
}
/* Style for the input field */
.cascadingInput {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    background-color: #f7f7f7;
  }
  
  /* Style for the input field on focus */
  .cascadingInput:focus {
    border-color: #007BFF; 
    box-shadow: 0 0 5px #007BFF; 
  }
