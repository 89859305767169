.modalNewFields {
  display: flex;
  flex-direction: column;
}

.modalNewFields > input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  background-color: #f7f7f7;
}

.modalNewFields:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px #007bff;
}

.customerBilledAdvancePaidDiv {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.customerBilledAdvancePaidDiv > div {
  width: 50%;
}
.customerBilledAdvancePaidDiv > div > input {
  width: 100%;
}

.alertAllFieldsAreMandatory {
  font-size: small;
  margin-top: 4px;
  width: 100%;
}

.trip-list-modal-btn-grp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
/* .submit-clear-button{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
  } */
