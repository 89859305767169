.online_payement_container {
  width: 100vw;
  height: 100vh;
  /* background-color: aqua; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.online_payement_wrapper {
  width: 30%;
  height: 70%;
  margin: 0 auto;
  border-radius: 20px;
  /*background-color: blue;
    */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.online_headings {
  padding: 2rem 0.5rem;
  width: 90%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom-right-radius: 150px;
  background-color: #e8f9e0;
}
.headings_payments {
  font-size: 1.3rem;
}
.payment_record_id {
  padding: 1rem 2rem;
  width: 90%;
  height: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.payment_record_name {
  padding: 1rem 1rem;
  width: 90%;
  height: 6%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.payment_record_text {
  font-size: 0.8rem;
}
.payment_id {
  font-size: 0.8rem;
  color: grey;
  /* margin-left: .5rem; */
}
.payment_name_div {
  padding: 0rem 1rem;
  display: flex;
  width: 100%;
  align-items: center;
  /* flex-direction: column; */
  justify-content: space-between;
  flex: 6;
  /* background-color: aqua; */
}
.payment_phone {
  font-size: 0.8rem;
  font-weight: bold;
  color: grey;
}
.payment_record_name {
  font-size: 1.2rem;
}
.payment_record_amount {
  font-size: 1.2rem;
}
.payment_record_invoice {
  padding: 2rem 2rem;
  width: 90%;
  margin-top: 3rem;
  height: 6%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background-color: aquamarine; */
}
.invoice {
  margin-left: 1rem;
}
.button_div {
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button_payment {
  padding: 1rem 2rem;
  background-color: green;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
@media only screen and (max-width: 499px) {
  .online_payement_container {
    width: 100vw;
    height: 100vh;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .online_payement_wrapper {
    width: 90%;
    height: 60%;
    margin: 0 auto;
    border-radius: 20px;
    /*background-color: blue;
        */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
  .online_headings {
    padding: 2rem 0.5rem;
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 0px;
    background-color: #e8f9e0;
  }
  .headings_payments {
    font-size: 1rem;
  }
  .payment_record_id {
    padding: 1rem 2rem;
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .payment_record_name {
    padding: 1rem 1rem;
    width: 90%;
    height: 6%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .payment_record_text {
    font-size: 0.8rem;
  }
  .payment_id {
    font-size: 0.8rem;
    color: grey;
    /* margin-left: .5rem; */
  }
  .payment_name_div {
    padding: 0rem 1rem;
    display: flex;
    width: 100%;
    align-items: center;
    /* flex-direction: column; */
    justify-content: space-between;
    flex: 6;
    /* background-color: aqua; */
  }
  .payment_phone {
    font-size: 0.8rem;
    font-weight: bold;
    color: grey;
  }
  .payment_record_name {
    font-size: 1rem;
  }
  .payment_record_amount {
    font-size: 1.2rem;
  }
  .payment_record_invoice {
    padding: 2rem 2rem;
    width: 90%;
    margin-top: 3rem;
    height: 6%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background-color: aquamarine; */
  }
  .invoice {
    margin-left: 1rem;
  }
  .button_div {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button_payment {
    padding: 1rem 2rem;
    background-color: green;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 4rem;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
  }
}
