.container {
  padding: 0.5rem 0rem;
  width: 98%;
  margin: 0 auto;
  word-wrap: break-word;
}
.text {
  color: #6c706e;
  word-wrap: normal;
  word-wrap: break-word;
  font-family: "Times New Roman";
  font-size: 1rem;
  /* color: grey; */
}
.heading {
  color: #086b15;
  margin-top: 1.5rem;
}
li {
  margin: 0.5rem 0rem;
}
@media only screen and (max-width: 499px) {
  .container {
    padding: 0.5rem 0rem;
    width: 93%;
    margin: 0 auto;
    word-wrap: break-word;
  }
  .text {
    color: #6c706e;
    word-wrap: normal;
    word-wrap: break-word;
    font-family: "Times New Roman";
    font-size: .7rem;
    /* color: grey; */
  }
  .heading {
    color: #086b15;
    margin-top: 1.5rem;
  }
}
