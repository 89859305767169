.complete-trip-modal-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}
.complete-trip-error {
  font-size: 11px;
  color: red;
}
.create-trip-from-row-grid {
  grid-template-columns: 100%;
}
.create-trip-from-row {
  display: flex;
  width: 100%;
}
.create-trip-save-btn {
  padding: 10px;
  width: 80%;
  margin: 10px 0px;
  background-color: #016938;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.create-trip-right-most-head {
  /* background-color: #f9f9f9; */
  color: #016938;
  font-size: larger;
  margin: 15px 5px;
  font-weight: 600;
}
.create-trip-input-container {
  flex: 1;
  /* background-color: #016938; */
  /* width: 100%; */
  margin: 10px;
  /* margin-bottom: 20px; */
}
.create-trip-drawer-container {

  margin-left: 10px;
  width: 400px;
}
.create-trip-add-cost {
  padding: 10px;
  color: #ffffff;
  background-color: #016938;

  border: none;
  border-radius: 10px;
  margin: 10px;
  margin-right: 30px;
}
.create-trip-add-cost-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  /* margin-right: auto; */
}
.complete-trip-cost-remove {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0.2;
}
.complete-trip-attachment-name {
  /* float: inline-end; */
  font-size: 11px;
  float: right;
  /* display: flex;
  align-items: center;
  justify-content: center; */

  margin-right: 30%;
  /* align-self: center; */
  /* justify-self: flex-end; */
}
.complete-trip-cost-left-side {
  flex: 1;
}
.complete-trip-input-attachment {
  width: 70%;
}
.complete-trip-edit-cost-btn {
  padding: 10px;
  background-color: #016938;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  margin: 0px 5px;
}
.complete-trip-drivers-box-row-cost {
  padding: 10px;
  margin: 10px 0px;
  border: 1px solid black;
  border-radius: 10px;
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.complete-trip-drivers-left {
  width: 42%;
  font-weight: 500;
}
.complete-trip-drivers-right {
  color: rgb(48, 47, 47);
  font-weight: 500;
}
.complete-trip-drivers-box-row {
  display: flex;
  margin: 5px 0px;
}
.complete-trip-modal-head {
  font-weight: 500;
  font-size: 14px;
}
.complete-trip-add-attachment-submit {
  padding: 10px;
  margin: 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #016938;
  font-weight: 500;
  color: #ffffff;
}
.complete-trip-add-attachment {
  padding: 10px;
  margin: 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #046589;
  font-weight: 500;
  color: #ffffff;
}
.complete-trip-modal-input {
  border: 1px solid gray;
  padding: 5px;
  width: 80%;
}
.complete-trip-modal-input-group {
  flex: 1;
  margin: 15px 0px;
  /* width: 70%; */
  /* display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column; */
}
.complete-trip-loading {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.complete-trip-main-container {
  display: flex;
}
.complete-trip-right-container {
  flex: 9.5;
  height: 99vh;
  position: relative;
  overflow: scroll;
}
.complete-trip-input-cost {
  width: 70%;
}
.complete-trip-cost {
  margin: 10px;
}
.complete-trip-sidebar {
  flex: 1.7;
}
.complete-trip-invoice-grid {
  display: grid;
  grid-template-columns: auto auto;
}

.complete-trip-single-reference {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 3px;
}
.complete-trip-head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 14px 0px;
  font-weight: 500;
  font-size: 16px;
}
.trip-list-reference-list {
  margin: 10px;
}
.trip-list-reference-list-table {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  width: 69%;
  border: 1px solid black;
}
.complete-trip-reference-number {
  margin-left: 6px;
  font-size: 14px;
  font-weight: 500;
}
.complete-trip-head-text {
  font-weight: 500;
  margin: 0px 10px;
  font-size: 18px;
  color: rgb(43, 43, 43);
}
.complete-trip-form-container {
  margin: 10px;
}
.complete-trip-textarea {
  padding: 5px;
  width: 70%;
}
.complete-trip-input-head {
  margin: 5px 0px;
  font-weight: 500;
}
.complete-trip-input-container {
  margin: 10px;
  flex: 1;
}
.complete-trip-button-container {
  margin: 20px 5px;
  flex: 1;
}
.column003 {
  width: 10%;
  font-size: 0.8rem;
  /* border-bottom: 2px solid black; */
  /* margin-left: 3rem; */
}
.delivery-invoice-quantity-input-complete {
  padding: 5px;
  width: 60%;
}
.column001 {
  width: 10%;
  font-size: 0.8rem;
  margin: 1.5rem 1rem;
  padding: 0.6rem 0rem;
  /* border-bottom: 2px solid black; */
  /* background-color: red; */
}
.delivery-invoice-quantity-input-complete {
  padding: 3px;
  width: 25%;
  margin-left: 10rem;
}

.table-head-row-complete {
  width: 100%;
  /* border-bottom: 10px solid black; */
  background-color: #6b6c6b;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  color: white;
}
.thead_heading-complete {
  width: 100%;
  /* border-bottom: 2px solid black; */
}
@media only screen and (max-width: 499px) {
  .create-trip-from-row-grid {
    grid-template-columns: 100%;
  }
  .create-trip-from-row {
    display: flex;
    width: 100%;
  }
  .create-trip-save-btn {
    padding: 10px;
    width: 80%;
    margin: 10px 0px;
    background-color: #016938;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  .create-trip-right-most-head {
    /* background-color: #f9f9f9; */
    color: #016938;
    font-size: larger;
    margin: 15px 5px;
    font-weight: 600;
  }
  .create-trip-input-container {
    flex: 1;
    /* background-color: #016938; */
    /* width: 100%; */
    margin: 10px;
    /* margin-bottom: 20px; */
  }
  .create-trip-drawer-container {
    margin-left: 10px;
    width: 300px;
  }
  .create-trip-add-cost {
    padding: 10px;
    color: #ffffff;
    background-color: #016938;
  
    border: none;
    border-radius: 10px;
    margin: 10px;
    margin-right: 30px;
  }
  .create-trip-add-cost-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* margin-right: auto; */
  }
  .column001 {
    width: 10%;
    font-size: 0.8rem;
    margin: 1.5rem 1rem;
    padding: 0.6rem 0rem;
    /* background-color: red; */
  }
  #invoice-items-complete th {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    background-color: white;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid black;
    font-weight: 500;
  }
  .column003 {
    width: 10%;
    font-size: 0.8rem;
    margin: 1.5rem 1rem;
    /* background-color: blueviolet; */
  }
  /* 
  .table-head-row-complete{
    width: 100%;
    background-color: #6ad1a1;
  } */

  .delivery-invoice-quantity-input-complete {
    padding: 3px;
    width: 35%;
    margin-left: 2rem;
  }
  .trip-list-reference-list-table {
    /* background-color: red; */
    width: 98%;

    margin: 1rem auto;
  }
  .complete-trip-main-container {
    flex-direction: column;
  }
  .complete-trip-input-head {
    font-size: 14px;
  }
  .complete-trip-textarea {
    width: 95%;
  }
  .complete-trip-input-container {
    width: 100%;
  }
  .complete-trip-invoice-grid {
    display: grid;
    grid-template-columns: auto;
  }
  .complete-trip-cost {
    margin: 10px;
  }
  .complete-trip-form-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  .complete-trip-input-cost {
    width: 90%;
  }
  .complete-trip-cost-input {
    width: 100%;
  }
  .complete-trip-cost {
    width: 100%;
  }
  .complete-trip-modal-row {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
  .complete-trip-modal-head {
    font-weight: 500;
    font-size: 14px;
  }
  .complete-trip-modal-input-group {
    margin: 15px 0px;
    width: 100%;
  }
  .complete-trip-modal-add-hidden {
    display: none;
  }
  .complete-trip-modal-input {
    border: 1px solid gray;
    padding: 5px;
    width: 100%;
  }
  .complete-trip-extra-input {
    width: 100%;
  }
  .complete-trip-extra-input-container {
    width: 90%;
    margin: 10px 0px;
  }
  .complete-trip-drivers-box-row-cost {
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
  }
  .complete-trip-cost-left-side {
    width: 100%;
  }
  .complete-trip-drivers-left {
    width: 60%;
  }
  .complete-trip-edit-cost-btn {
    margin: 0px;
  }
  .complete-trip-input-attachment {
    width: 90%;
  }
  .complete-trip-attachment-name {
    float: right;
    /* align-self: flex-end; */
    margin: 3px;
    margin-right: 10%;
  }
  .complete-trip-button-container {
    margin: 10px 5px;
  }
}
