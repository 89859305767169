.trip-list-main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trip-list-button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.trip-list-export-button {
  background-color: #016938;
  color: #ffffff;
  padding: 6px;
  margin: 10px;
  border-radius: 10px;
  border: none;
  width: 100px;
  /* border: 1px solid rgb(74, 73, 73); */
  cursor: pointer;
  font-weight: 500;
}
.trip-list-export-button:hover {
  background-color: #058549;
}
.trip-list-monthly-img-img {
  width: 24px;
  height: 24px;
}
.trip-list-monthly-text {
  font-size: 14px;
  margin: 2px;
  color: #016938;
}
.trip-list-export-text {
  font-weight: 500;
  font-size: 14px;
}
.trip-list-export-row {
  display: flex;
}
.trip-list-export-input-container {
  flex: 1;
  margin: 5px;
  width: 20%;
}
.trip-list-export-inputfield {
  flex: 1;
  width: 90%;
  /* margin: 0px 5px; */
}
.trip-list-export-options-head {
  margin: 10px 0px;
}
.trip-list-monthly-img {
  flex: 1;
  margin-left: 90%;
  margin-top: 5px;

  display: flex;
}
.trip-list-payout-container {
  display: flex;
}
.trip-list-input-attachment {
  width: 90%;
}
.error-trip {
  font-size: 13px;
  color: red;
}
.trip-list-filter-inputfield {
  width: 90%;
}
.trip-list-attachment-name {
  /* float: right; */
  font-size: 11px;
  align-self: center;
  /* justify-self: flex-end; */
}
.trip-list-single-top {
  display: flex;
}
.trip-list-single-bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.trip-list-bottom-left {
  font-size: 14px;
  font-weight: 500;
  margin: 5px;
}
.trip-list-bottom-right {
  font-size: 14px;
  font-weight: 500;
  margin: 5px;
  color: #575757;
}
.trip-list-bottom-right-container {
  flex: 1;
  /* width: 50%; */
  display: flex;
}
.trip-list-bottom-left-container {
  flex: 1;
  /* width: 50%; */
  /* display: flex; */
}
.trip-list-bottom-right-contact {
  font-size: 12px;
  font-weight: 500;
  margin: 5px;
  color: #575757;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.trip-list-last-modified {
  font-size: 11px;
  font-weight: 500;
  margin-left: 14px;
  width: 10%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.trip-list-loading-more {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  color: #016938;
}

.trip-list-modal-container {
  max-height: 500px;
  height: 95%;
  overflow: scroll;
}
.trip-list-sidebar {
  flex: 1.7;
  width: 100%;
  height: 100vh;
}
.trip-list-edit-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trip-list-middle-container {
  /* overflow: hidden; */
  height: 80vh;
  position: sticky;
  overflow-y: scroll;
  border-top: 1px solid #757575;
}
.trip-list-inner-left {
  flex: 0;
  width: 100%;
  display: flex;
  align-items: center;
  /* position: sticky; */
}
.trip-list-inner-right {
  flex: 3;
  width: 90%;
  display: flex;
  justify-content: center;
  /* background-color: #ffffff; */
}
.trip-list-filter-icon {
  margin-right: 6px;
  cursor: pointer;
}
.trip-list-filter-icon:hover {
  background-color: #dcd7d7;
  border-radius: 5px;
  /* padding: 5px; */
}
.trip-list-right-container {
  flex: 12;
  width: 100%;
  height: 100vh;
  /* position: relative; */
  background-color: #f9f9f9;
  padding: 0px 0px;
  /* position: sticky; */
  /* display: flex; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  /* overflow-y: scroll; */
}
.trip-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #575757;
}
.trip-circle-yellow {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: rgb(228, 228, 0);
  margin-right: 6px;
}
.trip-rectangle {
  width: 2px;
  height: 33px;
  background-color: #929292;
}
.trip-location-text-from {
  font-size: 12px;
  font-weight: 500;
  color: #575757;
  margin-top: 5px;
  margin-bottom: 2px;
  /* width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.trip-location-text-to {
  font-size: 12px;
  font-weight: 500;
  color: #575757;
  margin-bottom: 5px;
  margin-top: 2px;
  /* width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.trip-list-error {
  color: red;
  font-size: 12px;
  margin-left: 5px;
}
.trip-list-right-most-edit {
  /* margin-right: 40%; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.trip-location-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.trip-list-single-reference {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 3px;
}
.trip-list-reference-number {
  margin-left: 6px;
  font-size: 12px;
  font-weight: 500;
}
.trip-list-path {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  /* justify-content: center; */
}
.trip-list-address {
  display: flex;
  margin: 5px;
}
.trip-list-single-container {
  margin: 10px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  position: sticky;
  flex-direction: column;
}
.trip-list-search-container {
  width: 56%;
  height: 40px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid gray;
  padding: 10px;
  /* background-color: red; */
  display: flex;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-start;
}
.trip-list-single-right {
  margin: 10px;
  flex: 4;
}
.trip-list-search {
  border: none;
  height: 34px;
  width: 95%;
}
.trip-list-search:focus {
  outline: none;
}
.trip-list-text {
  font-size: 14px;
  font-weight: 500;
  margin: 5px;
}

.trip-list-truck-img {
  height: 100px;
  width: 100px;
}
.trip-list-status {
  color: #016938;
  padding: 7px;
  width: min-content;
  border-radius: 10px;
  font-weight: 600;
  margin-left: 10px;
}
.trip-list-right-most {
  display: flex;
  margin-left: 5%;
  width: 10vw;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  justify-content: center;
}
.trip-list-complete-invoice-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.trip-list-right-most-btn-container {
  margin: 7px;
}
.trip-pickup-modal-head {
  display: flex;
}
.trip-list-approve-btn {
  padding: 8px;
  background-color: #016938;
  color: #ffffff;
  border-radius: 2rem;
  border: none;
  width: 100px;
  cursor: pointer;
  font-weight: 500;
}
.trip-list-modal-text-input {
  width: 90%;
  font-weight: 500;
  padding: 5px;
  border: 1px solid gray;
  /* margin: 10px; */
}
.trip-list-reject-btn {
  padding: 7px;
  color: #016938;
  background-color: #ffffff;
  border-radius: 2rem;
  border: 2px solid #016938;
  width: 100px;
  cursor: pointer;
  font-weight: 500;
}
.trip-list-modal-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 9px 0px;
}
.trip-list-add-attachment {
  padding: 10px;
  margin: 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #016938;
  font-weight: 500;
  color: #ffffff;
}
.trip-list-modal-input {
  border: 1px solid gray;
  padding: 5px;
  width: 80%;
}
.trip-list-modal-input-group {
  flex: 1;
  width: max-content;
}
.trip-list-close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
}
.trip-list-submit-btn {
  background-color: #016938;
  color: #ffffff;
  border-radius: 7px;
  margin: 10px 0px;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100px;
}
.trip-list-modal-head {
  font-weight: 500;
  margin: 5px 0px;
}

.trip-list-whole {
  /* width: 100%; */
}

.trip-list-search-row {
  /* background-color: red; */
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
  align-items: center;
}
/* .MuiTabs-flexContainer {
  height: 20px !important;
} */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  padding: 10px !important;
  min-height: 30px !important;
}
.css-1ujnqem-MuiTabs-root {
  min-height: 30px !important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #016938 !important;
}
.create-new-trip-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.create-new-trip-button {
  background-color: #016938;
  color: #ffffff;
  border-radius: 10px;
  border: none;
  margin-right: auto;
  font-weight: 500;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}

.coverage-button {
  background-color: #fff1af;
  color: #000;
  border-radius: 10px;
  border: none;
  margin-right: auto;
  font-weight: 500;
  padding: 10px;
  width: 40px;
  margin: 10px;
  cursor: pointer;
  border: 1px solid none;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.coverage-button:hover .map-tooltip {
  display: block;
}

.map-tooltip {
  position: absolute;
  left: 50px;
  display: none;
  font-size: 12px;
  color: #454545;
}
.create-new-trip-button:hover {
  background-color: #058549;
  /* border: 1px solid black; */
}

.export-report-trip-button {
  background-color: #f9f9f9;

  color: #016938;
  border-radius: 10px;
  border: 1px solid #016938;
  margin-right: auto;
  font-weight: 500;
  padding: 10px;
  margin: 0px 10px;
  cursor: pointer;
}
.export-button-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.export-report-trip-button:hover {
  background-color: #ffffff;
  /* border: 1px solid black; */
}
.trip-list-filter-input-right {
  position: absolute;
  left: 45%;
  width: 65%;
}
.trip-list-filter-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin: 10px 0px;
}
.trip-list-filter-container {
  margin: 10px;
}
.trip-list-filter-main {
  padding: 10px;
  background-color: #ffffff;

  /* width: 100vw; */
}
.trip-list-export-main {
  padding: 10px;
  background-color: #ffffff;
  /* width: 100vw; */
}
.trip-list-filter-main-text {
  font-weight: 500;
  font-size: 18px;
}
.trip-list-filter-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.trip-list-filter-button-row {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0px;
  width: 98%;
}
.trip-list-filter-button {
  padding: 6px;
  margin: 10px;
  border-radius: 10px;
  border: none;
  width: 100px;
  border: 1px solid rgb(74, 73, 73);
  cursor: pointer;
  font-weight: 500;
}
.trip-list-filter-text {
  font-weight: 500;
}

.tl-new-main-left {
  display: flex;
  justify-content: space-between;
  flex: 8;
}
.tl-new-single-container {
  display: flex;
  padding: 5px 14px;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  background: #ffffff;
  margin: 10px;
  justify-content: space-between;
  cursor: pointer;
}
.tl-new-tripcode {
  color: #016938;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}
.tl-new-vehicle {
  color: #454545;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}
.tl-new-status {
  color: #016938;
  font-weight: 700;
  font-size: 12px;
  line-height: 21px;
}
.tl-new-modifiedby {
  color: #454545;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.tl-new-middle {
  text-align: end;
  /* flex: 4; */
}
.tl-new-left {
  /* flex: 4; */
}
.tl-new-payterm {
  color: #454545;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
.tl-new-inner-div {
  margin: 3px 0px;
}
.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 4px !important;
}
.tl-new-right {
  flex: 2;
  display: flex;
  justify-content: flex-end;
}
/* mobile responsive css*/

@media only screen and (max-width: 499px) {
  .tl-new-single-container {
    flex-direction: column;
  }
  .trip-list-right-most-single-container {
    display: grid;
    grid-template-columns: auto auto;
  }
  .trip-list-sidebar {
    flex: 0;
    height: 0px;
  }
  .trip-pickup-modal-head {
    flex-direction: column;
  }

  .trip-list-filter-container {
    margin: 5px;
    height: 67vh;
  }

  .trip-list-filter-input-right {
    left: 55%;
    width: 60%;
  }

  .trip-list-filter-row {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 0px;
  }
  .trip-list-main-container {
    width: 100%;
    flex-direction: column;
    height: auto;
    position: relative;
  }
  .trip-list-mobile-header {
    width: 100%;
    height: 50px;
  }
  .trip-list-search-row {
    flex-direction: column;
    margin: 10px 0px;
  }
  .trip-list-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  .trip-list-payout-container {
    flex-direction: column-reverse;
  }
  .create-new-trip-button {
    align-self: flex-end;
    margin-right: 15px;
  }
  .trip-list-right-container {
    width: 100%;
    padding: 5px;

    /* position: relative; */
  }
  .trip-list-search-container {
    width: 90%;
  }
  .trip-list-single-container {
    flex-direction: column;
  }
  .trip-list-single-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .trip-list-right-most {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .trip-list-monthly-img {
    margin-left: 2%;
    margin-top: 0px;
  }
  .trip-list-right-most-single-container {
    display: flex;
  }
  .trip-list-modal-container {
    width: 100%;
    max-height: 80vh;
  }
  .trip-list-modal-row {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
  .trip-list-modal-head-hidden {
    font-weight: 500;
    font-size: 14px;
    display: none;
  }
  .trip-list-modal-input-group {
    margin: 10px 0px;
    width: 100%;
  }
  .trip-list-modal-add-hidden {
    display: none;
  }
  .trip-list-modal-input {
    border: 1px solid gray;
    padding: 5px;
    width: 100%;
  }
  .trip-list-add-attachment {
    background-color: rgb(113, 6, 109);
  }
  .trip-list-modal-text-input {
    width: 100%;
  }
  .trip-list-waiting {
    width: 100%;
    display: flex;
  }
  .trip-list-drawer-top-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
  }
  .trip-list-trip-details-drawer-head {
    font-weight: 500;
    color: #016938;
    font-size: 17px;
  }
  .trip-list-single-top {
    display: flex;
    flex-direction: column;
  }
  .trip-list-single-bottom-container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .trip-list-attachment-name {
    float: right;
    align-self: flex-end;
    margin: 3px;
    margin-right: 10px;
  }
  .trip-list-input-attachment {
    width: 100%;
  }
  .trip-list-filter-main {
    padding: 0px;
    height: 68vh;
    overflow: scroll;
  }
  .trip-list-export-row {
    flex-direction: column;
  }
  .trip-list-export-container {
    height: 100vh;
  }
  .trip-list-export-main {
    height: 100%;
    overflow: scroll;
  }
  .trip-list-export-input-container {
    width: 100%;
  }
  /* .box :nth-child(1) { order: 2; }
        .box :nth-child(2) { order: 3; }
        .box :nth-child(3) { order: 1; }
        .box :nth-child(4) { order: 3; }
        .box :nth-child(5) { order: 1; } */
}
