::-webkit-scrollbar {
    display: none;
    /* scrollbar-width: thin; */
    /* scrollbar-width: none; */
  }

 
.ant-dropdown-trigger
  .ant-dropdown-open {
  z-index: 1056;
}


  .ant-picker {
    z-index: 1056;
  }
