.mini-details-warehouseuser-tab-container-new {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  overflow: scroll;
  height: 45vh;
  /* background-color: red; */
}

.warehouse-mini-img-company {
  height: 100%;
  width: 80%;
}
.warehouse-mini-img-container {
  height: 30%;
}
.usersContainer-warehouse {
  width: 100%;
  /* margin: 0 auto; */
  height: 100%;
  padding: 0px;
  margin: 10px 0px;
  /* flex:10; */
}
.warehouse-minicard-button {
  color: white;
  background-color: #016938;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  border: none;
  font-weight: 500;
  cursor: pointer;
}
.mini-card-single-accordion-container {
}
.mini-card-warehouse-user {
  width: 23vw;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: flex-start;
  margin: 5px 0px;
  /* justify-content: center; */
}
.mini-card-warehouse-user-right {
  position: absolute;
  font-size: 14px;
  color: rgb(82, 81, 81);
  left: 45%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mini-card-warehouse-user-left {
  position: relative;
  font-size: 14px;
}
.warehouse-mini-no-users {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  width: 100%;
}
.mobile_users {
  width: 18rem;
  /* margin: 0 auto; */
  display: flex;
  margin-left: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* overflow: hidden; */
  /* margin-left: 1rem; */
}
@media only screen and (max-width: 480px) {
  .mini-card-single-accordion-container {
    width: 100%;
  }

  .mobile_users {
    width: 20rem;
    /* margin: 0 auto; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* overflow: hidden; */
    /* margin-left: 1rem; */
  }
  .warehouse-minicard-button {
    color: white;
    background-color: #016938;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    border: none;
    font-weight: 500;
    cursor: pointer;
  }
  .mini-card-warehouse-user-right {
    position: absolute;
    font-size: 14px;
    color: rgb(82, 81, 81);
    left: 45%;
  }
  .mini-details-warehouseuser-tab-container-new {
    height: 66vh;
  }
}
